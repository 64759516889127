
































import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"

@Component({})
export default class HouseActionsTeam extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ default: 2 }) elevation!: number
  @Prop({ default: null }) agencyId!: number | null
  @Prop({ default: false }) grayBackgroundItem!: boolean | ""

  get actions_team() {
    if (this.agencyId) {
      return this.house.actions_team?.filter(
        ({ agency_id }) => agency_id == this.agencyId
      )
    }
    return this.house.actions_team
  }
}
